import { EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

import { PagedApiRequestParams } from '@@core/models/api.model';

export interface Application {
	id: string;
	name: string;
	addedOn?: number;
	addedBy?: string;
}

export type UserStatus = 'active' | 'inactive' | 'deleted';

export interface User {
	id?: string;
	firstName: string;
	lastName: string;
	fullName?: string;
	email: string;
	role: Role;
	isSsoUser?: boolean;
	permissions?: Permission[];
	permissionIds?: string[];
	status?: UserStatus;
	passwordStatus?: PasswordStatus;
	created?: number;
	lastSignIn?: number;
	avatar?: string;
	externalId?: string;
	groups?: Group[];
	groupIds?: string[];
	groupMapping?: boolean;
	addedOn?: number;
	addedBy?: string;
}

export interface UsersBatchUpdatePayload {
	userIds: string[];
	status?: UserStatus;
	role?: Role;
}

export interface UsersBatchDeletePayload {
	userIds: string[];
}

export interface UsersBatchDelete {
	userIds: string[];
	errors?: string[];
}

export interface UsersBatchUpdate {
	users: User[];
	errors?: string[];
}

export type Role = 'user-limited' | 'user' | 'user-admin' | 'user-devops' | 'sealights-admin';
export const ROLES: Role[] = ['user-limited', 'user', 'user-admin', 'user-devops'];

export type GroupStatus = 'active' | 'inactive' | 'deleted';

export interface Group {
	id?: string;
	name: string;
	description: string;
	status?: GroupStatus;
	created?: number;
	users: User[];
	userIds?: string[];
	applications: Application[];
	applicationIds?: string[];
	externalId?: string;
	isSystemGroup?: boolean;
	includesAllApps?: boolean;
	addedOn?: number;
	addedBy?: string;
}

export type PermissionCode = 'up-management' | 'unassigned-apps';

export interface Permission {
	id: string;
	name: PermissionCode;
	description?: string;
}

export interface CreateUserDialogData {
	newUser$: Subject<User>;
}

export type CreateUserDialogResult = 'user-details' | 'user-groups' | null;

export interface UploadUsersDialogData {
	uploadUsers$: Subject<File>;
	groups: Group[];
}

export interface UploadUsersResponse {
	users: User[];
	errors: string[];
}

export interface UsersGroupsDialogData {
	usersSelected: User[];
	groups: Group[];
	selectedGroups$: Subject<string[]>;
}

export interface CreateGroupDialogData {
	newGroup$: Subject<Partial<Group>>;
	apps: Application[];
}

export type CreateGroupDialogResult = 'group-details' | 'group-users' | null;

export interface AddUserGroupsDialogData {
	addedGroupIds$: Subject<string[]>;
	groups: Group[];
}

export interface AddGroupAppsDialogData {
	addedAppIds$: Subject<string[]>;
	apps: Application[];
}

export interface AddGroupUsersDialogData {
	addedUserIds$: Subject<string[]>;
	users: User[];
}

export interface AppsGroupsDialogData {
	appsCount: number;
	selectedGroups$: Subject<string[]>;
}

export interface UserUrlParams {
	id: string;
}

export interface GroupUrlParams {
	id: string;
}

export interface ApplicationsPagedApiRequestParams extends PagedApiRequestParams {
	path: ApplicationTypePath;
}


export interface GroupPagedApiRequestParams extends PagedApiRequestParams {
	status?: GroupStatus;
}

export enum ApplicationTypePath {
	UNASSIGNED = 'unassigned',
	ASSIGNED = 'assigned',
	ALL = 'all'
}

export enum PasswordStatus {
	OK = 'ok',
	TEMPORARY = 'temporary',
	TEMPORARY_EXPIRED = 'temporary-expired',
	RESET_CODE = 'reset-code',
	RESET_CODE_EXPIRED = 'reset-code-expired',
}

export const PasswordStatusMap = {
	[PasswordStatus.OK]: 'OK',
	[PasswordStatus.TEMPORARY]: 'Temporary password',
	[PasswordStatus.TEMPORARY_EXPIRED]: 'Temporary password expired',
	[PasswordStatus.RESET_CODE]: 'Reset code sent to user',
	[PasswordStatus.RESET_CODE_EXPIRED]: 'Reset code expired',
};

export interface UsersPagedApiRequestParams extends PagedApiRequestParams {
	permissionIds?: string[];
}

export interface PermissionFilterDialogData {
	onConfirm: EventEmitter<Permission[]>;
	filters: Permission[];
	selectedFilters: Permission[];
}

export class PermissionsState {
	userCreated: boolean;
	lastUserCreated: User;
	groupCreated: boolean;
	lastGroupCreated: Group;
	unassignedAppsCount: number;
}
