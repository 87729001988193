import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { getState } from '@ngrx/signals';
import { BehaviorSubject, catchError, map, merge, Observable, of, Subject, take, tap, throwError } from 'rxjs';

import { PagedResponse, PagedResponseWrapper, ResponseWrapper } from '@@core/models/api.model';
import { UserRole, UserRolePermission } from '@@core/models/auth/auth-model';
import { ApiService } from '@@core/services/utils/api.service';
import { APP_CONFIG } from '@@shared/providers/application-config-provider/application-config-provider.model';
import { AuthStore } from '@@shared/stores/auth-store/stores/auth.store';

import {
	Application, ApplicationsPagedApiRequestParams,
	ApplicationTypePath,
	Group, GroupPagedApiRequestParams, Permission, PermissionCode, PermissionsState, Role, UploadUsersResponse, User,
	UsersBatchDelete, UsersBatchDeletePayload, UsersBatchUpdate, UsersBatchUpdatePayload, UsersPagedApiRequestParams,
	UserStatus
} from '../model/permissions.model';

@Injectable({
	providedIn: 'root'
})
export class PermissionsService {
	mockApiBaseUrl = 'https://run.mocky.io/v3/';
	localMockApiBaseUrl = 'http://localhost:3001/';
	localMockApiDelay = 500;
	selectedUsers$ = new BehaviorSubject<User[]>([]);
	selectedUserGroups$ = new BehaviorSubject<Group[]>([]);
	deletedSelectedUsers$ = new Subject<boolean>();
	selectedGroups$ = new BehaviorSubject<Group[]>([]);
	selectedGroupApps$ = new BehaviorSubject<Application[]>([]);
	selectedGroupUsers$ = new BehaviorSubject<User[]>([]);
	selectedUnassignedApps$ = new BehaviorSubject<Application[]>([]);
	uploadingUsers$ = new Subject<boolean>();
	readonly #lastErrorMessage$ = new Subject<string>();
	lastErrorMessageObservable$ = this.#lastErrorMessage$.asObservable();
	readonly state$ = new BehaviorSubject<PermissionsState>({
		userCreated: false,
		lastUserCreated: null,
		groupCreated: false,
		lastGroupCreated: null,
		unassignedAppsCount: 0
	});
	stateObservable$ = this.state$.asObservable();
	readonly #loggedInUser$ = new BehaviorSubject<User>(null);
	loggedInUserObservable$ = this.#loggedInUser$.asObservable();
	readonly #permissions$ = new BehaviorSubject<Permission[]>([]);
	permissionsObservable$ = this.#permissions$.asObservable();
	#isSsoMappedGroups$ = new BehaviorSubject<boolean>(true);
	readonly isSsoMappedGroupsObservable$ = this.#isSsoMappedGroups$.asObservable();

	readonly #authStore = inject(AuthStore);
	readonly #apiBaseUrl = inject(APP_CONFIG)?.uri?.apiBaseUrl;
	readonly #usersApiBaseUrl = `${this.#apiBaseUrl}v1/users`;
	readonly #groupsApiBaseUrl = `${this.#apiBaseUrl}v1/groups`;
	readonly #httpClient = inject(HttpClient);
	readonly #apiService = inject(ApiService);


	/**
	 * USERS
	 */

	get loggedInUser(): User {
		return this.#loggedInUser$.value;
	}

	getUsers(params?: UsersPagedApiRequestParams): Observable<PagedResponseWrapper<User, PagedResponse<User>>> {
		const queryParams = this.#apiService.createPagedApiRequestParams(params);

		if (params.permissionIds) {
			queryParams.append('permissionIds', params.permissionIds.toString());
		}

		const url = `${this.#usersApiBaseUrl}?${queryParams.toString()}`;

		return this.#httpClient.get<PagedResponseWrapper<User, PagedResponse<User>>>(url);
	}

	getUser(userId: string): Observable<ResponseWrapper<User>> {
		const url = `${this.#usersApiBaseUrl}/${userId}`;

		return this.#httpClient.get<ResponseWrapper<User>>(url)
			.pipe(catchError(() => throwError(() => new Error('User not found'))));
	}

	getLoggedInUser(): Observable<ResponseWrapper<User>> {
		const url = `${this.#usersApiBaseUrl}/me`;

		return this.#httpClient.get<ResponseWrapper<User>>(url)
			.pipe(
				catchError(() => {
					const user = JSON.parse(localStorage.getItem('userData')) as User;

					this.#loggedInUser$.next(user);

					return of({
						data: user
					});
				}),
				tap(response => this.#loggedInUser$.next(response.data))
			);
	}

	userName(user: User): string {
		return `${user.firstName} ${user.lastName}`;
	}

	createUser(user: User): Observable<User> {
		const url = `${this.#apiBaseUrl}v1/users`;

		return this.#httpClient.post<ResponseWrapper<User>>(url, user)
			.pipe(
				catchError((error: HttpErrorResponse) => this.#apiService.handleApiError(error, (message: string) => this.#lastErrorMessage$.next(message))),
				map(response => response.data)
			);
	}

	updateUser(userId: string, updatedUserProperties: Partial<User>): Observable<ResponseWrapper<User>> {
		const url = `${this.#apiBaseUrl}v1/users/${userId}`;

		return this.#httpClient.patch<ResponseWrapper<User>>(url, updatedUserProperties)
			.pipe(catchError(() => throwError(() => new Error('Failed to update user'))));
	}

	updateUsers(userIds: string[], status?: UserStatus, role?: Role): Observable<ResponseWrapper<UsersBatchUpdate>> {
		const url = `${this.#apiBaseUrl}v1/users/batch-update`;
		const batchUpdate = {
			userIds
		} as UsersBatchUpdatePayload;

		if (status) {
			batchUpdate.status = status;
		}
		if (role) {
			batchUpdate.role = role;
		}

		return this.#httpClient.post<ResponseWrapper<UsersBatchUpdate>>(url, batchUpdate)
			.pipe(catchError(() => throwError(() => new Error('Failed to update users'))));
	}

	disableUser(userId: string): Observable<User> {
		return this.updateUser(userId, { status: 'inactive' })
			.pipe(
				catchError(() => throwError(() => new Error('Failed to deactivate user'))),
				map(response => response.data)
			);
	}

	disableUsers(userIds: string[]): Observable<ResponseWrapper<UsersBatchUpdate>> {
		return this.updateUsers(userIds, 'inactive');
	}

	enableUser(userId: string): Observable<User> {
		return this.updateUser(userId, { status: 'active' })
			.pipe(
				catchError(() => throwError(() => new Error('Failed to activate user'))),
				map(response => response.data)
			);
	}

	enableUsers(userIds: string[]): Observable<ResponseWrapper<UsersBatchUpdate>> {
		return this.updateUsers(userIds, 'active');
	}

	deleteUser(userId: string): Observable<void> {
		// return this.deleteUsers([userId]);
		const url = `${this.#apiBaseUrl}v1/users/${userId}`;

		return this.#httpClient.delete<void>(url)
			.pipe(
				catchError(() => throwError(() => new Error('Failed to delete user')))
			);
	}

	batchDeleteUsers(userIds: string[]): Observable<ResponseWrapper<UsersBatchDelete>> {
		const url = `${this.#apiBaseUrl}v1/users/batch-delete`;
		const batchDelete = {
			userIds
		} as UsersBatchDeletePayload;

		return this.#httpClient.post<ResponseWrapper<UsersBatchDelete>>(url, batchDelete)
			.pipe(catchError(() => throwError(() => new Error('Failed to update users'))));
	}

	deleteUsers(userIds: string[]): Observable<ResponseWrapper<UsersBatchDelete>> {
		return this.batchDeleteUsers(userIds);
	}

	resetPassword(userId: string): Observable<void> {
		const url = `${this.#apiBaseUrl}v1/users/${userId}/reset-password`;

		return this.#httpClient.post<void>(url, {})
			.pipe(catchError(() => throwError(() => new Error('Failed to reset password'))));
	}

	resendTemporaryPassword(userId: string): Observable<void> {
		const url = `${this.#apiBaseUrl}v1/users/${userId}/resend-temporary`;

		return this.#httpClient.post<void>(url, {})
			.pipe(catchError(() => throwError(() => new Error('Failed to resend temporary password'))));
	}

	bulkCreateUsers(file: File, createGroupOnFail: boolean): Observable<ResponseWrapper<UploadUsersResponse>> {
		const url = `${this.#apiBaseUrl}v1/users/batch-csv?createGroupOnFail=${createGroupOnFail ? 'true' : 'false'}`;
		const formData = new FormData();

		formData.append('file', file);

		this.uploadingUsers$.next(true);
		this.resetError();

		return this.#httpClient.post(url, formData)
			.pipe(catchError((error: HttpErrorResponse) => this.#apiService.handleApiError(error, (message: string) => this.#lastErrorMessage$.next(message))));
	}

	resetSelectedUsers(): void {
		this.selectedUsers$.next([]);
	}

	resetUploadingUsers(): void {
		this.uploadingUsers$.next(false);
	}

	/**
	 * USER GROUPS
	 */

	resetSelectedUserGroups(): void {
		this.selectedUserGroups$.next([]);
	}

	/**
	 * GROUPS
	 */

	getGroups(params?: GroupPagedApiRequestParams): Observable<PagedResponseWrapper<Group, PagedResponse<Group>>> {
		const queryParams = this.#apiService.createPagedApiRequestParams(params);
		if (params?.status) {
			queryParams.append('status', params.status);
		}
		const url = `${this.#groupsApiBaseUrl}?${queryParams.toString()}`;

		return this.#httpClient.get<PagedResponseWrapper<Group, PagedResponse<Group>>>(url);
	}

	getGroup(groupId: string): Observable<ResponseWrapper<Group>> {
		const url = `${this.#groupsApiBaseUrl}/${groupId}`;

		return this.#httpClient.get<ResponseWrapper<Group>>(url)
			.pipe(
				map(group => {
					group.data.users = group.data.users ?? [];
					group.data.applications = group.data.applications ?? [];

					return group;
				}),
				catchError(() => throwError(() => new Error('Group not found')))
			);

	}

	createGroup(group: Partial<Group>): Observable<Group> {
		const url = `${this.#apiBaseUrl}v1/groups`;

		return this.#httpClient.post<ResponseWrapper<Group>>(url, group)
			.pipe(
				catchError((error: HttpErrorResponse) => this.#apiService.handleApiError(error, (message: string) => this.#lastErrorMessage$.next(message))),
				map(response => response.data)
			);
	}

	updateGroup(groupId: string, updatedGroupProperties: Partial<Group>): Observable<ResponseWrapper<Group>> {
		const url = `${this.#apiBaseUrl}v1/groups/${groupId}`;

		return this.#httpClient.patch<ResponseWrapper<Group>>(url, updatedGroupProperties)
			.pipe(catchError(() => throwError(() => new Error('Failed to update group'))));
	}

	updateGroups(groupIds: string[], updatedGroupProperties: Partial<Group>): Observable<any> {
		const updates = groupIds.map(groupId => this.updateGroup(groupId, updatedGroupProperties));

		return merge(...updates);
	}

	deleteGroup(groupId: string): Observable<void> {
		const url = `${this.#apiBaseUrl}v1/groups/${groupId}`;

		return this.#httpClient.delete<void>(url)
			.pipe(
				catchError(() => throwError(() => new Error('Failed to delete group')))
			);
	}

	deleteGroups(groupIds: string[]): Observable<any> {
		const deletes = groupIds.map(groupId => this.deleteGroup(groupId));

		return merge(...deletes);
	}

	addGroupUsers(groupId: string, addedUserIds: string[]): Observable<ResponseWrapper<Group>> {
		const url = `${this.#apiBaseUrl}v1/groups/${groupId}/add-users`;

		return this.#httpClient.post<ResponseWrapper<Group>>(url, addedUserIds)
			.pipe(catchError(() => throwError(() => new Error('Failed to add users to group'))));
	}

	addGroupApplications(groupId: string, addedApplicationIds: string[]): Observable<ResponseWrapper<Group>> {
		const url = `${this.#apiBaseUrl}v1/groups/${groupId}/add-applications`;

		return this.#httpClient.post<ResponseWrapper<Group>>(url, addedApplicationIds)
			.pipe(catchError(() => throwError(() => new Error('Failed to add applications to group'))));
	}

	resetSelectedGroups(): void {
		this.selectedGroups$.next([]);
	}

	sortGroupsByNameWithSystemFirst(groups: Group[]): Group[] {
		return [...groups].sort((prev, curr) => `${prev.isSystemGroup ? '0' : '1'}${prev.name}`.localeCompare(`${curr.isSystemGroup ? '0' : '1'}${curr.name}`));
	}

	/**
	 * GROUP USERS
	 */

	resetSelectedGroupUsers(): void {
		this.selectedGroupUsers$.next([]);
	}

	/**
	 * GROUP APPS
	 */

	resetSelectedGroupApps(): void {
		this.selectedGroupApps$.next([]);
	}

	/**
	 * APPS
	 */

	getApplicationsV2(params?: ApplicationsPagedApiRequestParams): Observable<PagedResponseWrapper<Application, PagedResponse<Application>>> {
		const queryParams = this.#apiService.createPagedApiRequestParams(params);
		const url = `${this.#apiBaseUrl}v2/applications/${params.path.toString()}?${queryParams.toString()}`;

		return this.#httpClient.get<PagedResponseWrapper<Application, PagedResponse<Application>>>(url);
	}

	resetSelectedUnassignedApps(): void {
		this.selectedUnassignedApps$.next([]);
	}

	/**
	 * PERMISSIONS
	 */

	getPermissions(): Observable<ResponseWrapper<Permission[]>> {
		const url = `${this.#apiBaseUrl}v1/permissions`;

		return this.#httpClient.get<ResponseWrapper<Permission[]>>(url)
			.pipe(
				tap(response => this.#permissions$.next(response.data)),
				catchError(() => throwError(() => new Error('Failed to fetch permissions'))),
			);
	}

	/**
	 * ERRORS
	 */

	resetError(): void {
		this.#lastErrorMessage$.next(null);
	}

	mapPermissionToApplicationPath(): ApplicationTypePath {
		const permissions = this.#loggedInUser$.value.permissions;
		const role = this.#loggedInUser$.value?.role as UserRole;

		if (permissions.some(permission => permission.name === 'up-management')) {
			if ([UserRole.UserAdmin, UserRole.UserDevops, UserRole.SealightsAdmin].includes(role)) {
				return ApplicationTypePath.ALL;
			} else {
				return ApplicationTypePath.ASSIGNED;
			}
		} else {
			if ([UserRole.UserAdmin, UserRole.UserDevops, UserRole.SealightsAdmin].includes(role)) {
				return ApplicationTypePath.UNASSIGNED;
			}
		}
	}

	// From auth service

	// Should be moved after permission service refactor
	userHasPermission(permissionName: PermissionCode): boolean {
		return this.#authStore.isSealightsAdminUser() || (this.loggedInUser?.permissions ?? [])?.some(permission => permission.name === permissionName);
	}

	// This should not be here. it is not part of auth
	refreshUnassignedApplicationsCount(): void {
		if (UserRolePermission.SETTINGS.includes(this.#authStore.getUserRole())) {
			this.getApplicationsV2({ path: ApplicationTypePath.UNASSIGNED, pageSize: 1 })
				.pipe(
					take(1),
					tap(response => this.state$.next({ ...this.state$.value, unassignedAppsCount: response.data.total }))
				)
				.subscribe();
		}
	}

	getUserProfile(): Observable<ResponseWrapper<User>> {
		if (!this.loggedInUser) {
			const state = getState(this.#authStore);
			return this.getLoggedInUser()
				.pipe(
					tap(response => {
						this.#isSsoMappedGroups$.next(response.data.isSsoUser && response.data.groupMapping);
						this.#authStore.save({
							user: {
								...state.user,
								email: response.data.email,
								role: response.data.role as UserRole,
								name: response.data.firstName,
								family_name: response.data.lastName,
							}
						});
					})
				);
		} else {
			return of(null);
		}
	}
}
